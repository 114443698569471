import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";

const FaviconWithFallbacks = ({ domain, doCache, store }) => {
  const [src, setSrc] = useState("");
  const [fallbackIndex, setFallbackIndex] = useState(0);

  const cachedIcon = doCache && store.cachedFavIcons.get(domain);

  const fallbackUrls = [
    "/images/favicon.ico",
    `https://www.google.com/s2/favicons?domain=${domain}&sz=512`,
  ];

  useEffect(() => {
    setSrc(`${domain}/favicon.ico`);
  }, [domain]);

  const onError = () => {
    if (fallbackIndex >= fallbackUrls.length) {
      return;
    } else if (fallbackIndex === fallbackUrls.length - 1) {
      setSrc(fallbackUrls[fallbackIndex]);
    } else {
      setSrc(`${domain}${fallbackUrls[fallbackIndex]}`);
    }
    setFallbackIndex(fallbackIndex + 1);
  };

  return (
    <img
      src={cachedIcon || src}
      onError={() => onError()}
      onLoad={() => doCache && !cachedIcon && store.cacheFavIcon(domain, src)}
      alt="favorite icon"
    ></img>
  );
};

export default observer(FaviconWithFallbacks);

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useStores } from "stores/utils";
import { reverse, sortBy } from "lodash";
import DocumentSquare from "./DocumentSquare";
import "./FoyerDocumentList.scss";

const FoyerDocumentList = ({ sessionId, groupList }) => {
  const { api, docStore } = useStores();
  const [groupedDocs, setGroupedDocs] = useState();
  const [groupDictionary, setGroupDictionary] = useState();
  const [groupIds, setGroupIds] = useState();

  useEffect(() => {
    setGroupIds(groupList.map((x) => x.id));
  }, [groupList]);

  const fetchDocuments = ({ queryKey }) => {
    const [, sessionId] = queryKey;
    return api.getSessionDocuments(sessionId);
  };

  const query = useQuery(["session-docs", sessionId], fetchDocuments);
  docStore.setFoyerQuery(query);

  useEffect(() => {
    if (query.data) {
      const groupDictionary = query.data.data.included
        .filter((x) => x.type === "groups")
        .reduce((accum, cur) => {
          accum.set(cur.id, cur);
          return accum;
        }, new Map());
      setGroupDictionary(groupDictionary);
    }
  }, [query.data]);

  useEffect(() => {
    if (query.data && groupIds) {
      const groupDocsAndLinks = query.data.data.included
        .filter((x) => ["group-docs", "group-links"].includes(x.type))
        .reduce((accum, cur) => {
          const groupId = cur.attributes["group-id"];
          if (!accum.has(groupId)) {
            accum.set(groupId, []);
          }
          accum.get(groupId).push(cur);
          return accum;
        }, new Map());

      groupDocsAndLinks.forEach((val, key, map) => {
        map.set(key, reverse(sortBy(val, (x) => x.attributes["updated-at"])));
      });

      const groupsSorted = [];
      groupIds.forEach((id) => {
        const group = groupDocsAndLinks.get(id);
        if (group) {
          groupsSorted.push([id, group]);
        }
      });
      setGroupedDocs(groupsSorted);
    }
  }, [query.data, groupIds]);

  if (!groupedDocs || groupedDocs.length === 0) {
    return <></>;
  } else {
    return (
      <div className="foyer-document-list">
        <h1>Documents</h1>
        {groupedDocs.map(([groupId, items]) => (
          <div className="room-docs" key={groupId}>
            <h2>{groupDictionary.get(groupId).attributes.name}</h2>
            <div className="doc-grid">
              {items.map((item) => (
                <DocumentSquare item={item} key={item.id} />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export default observer(FoyerDocumentList);

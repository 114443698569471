import { observer } from "mobx-react-lite";
import { useStores } from "stores/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import DocEditorsPopup from "components/Office/DocEditorsPopup";

const DocTitle = ({ docId, docName, editing, startEditing, stopEditing }) => {
  const { docStore } = useStores();

  const openSettings = () => {
    docStore.showDocumentSettingsDocId(docId);
  };

  let editButton;
  let settingsButton = (
    <div className="doc-pane-settings-button" onClick={openSettings}>
      <FontAwesomeIcon icon={faCog} />
    </div>
  );
  if (editing) {
    editButton = (
      <button
        onClick={stopEditing}
        className="button doc-edit-button doc-stop-button"
        id="button1"
      >
        Stop editing
      </button>
    );
  } else {
    editButton = (
      <Tippy
        content={<DocEditorsPopup docId={docId} />}
        disabled={!docStore.editorListFull}
        interactive={true}
        placement="bottom-end"
      >
        <div className="is-inline-block">
          <button
            onClick={startEditing}
            className={classNames("button doc-edit-button", {})}
            id="button2"
            disabled={docStore.editorListFull}
          >
            Edit
          </button>
        </div>
      </Tippy>
    );
  }

  return (
    <h1 className="doc-title">
      <span>{docName}</span>
      <nobr>
        {editButton} {settingsButton}
      </nobr>
    </h1>
  );
};

export default observer(DocTitle);

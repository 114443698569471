import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { isValidUrl } from "../../utilities/dom";
import FaviconWithFallbacks from "../FaviconWithFallbacks/FaviconWithFallbacks";
import { useStores } from "../../stores/utils";

const DocumentSquare = ({ item }) => {
  const { docStore } = useStores();
  const favSrc = isValidUrl(item.attributes.url)?.origin;

  if (item.type === "group-docs") {
    return (
      <a href={`/doc/${item.id}`} target="_blank" rel="noopener noreferrer">
        <div className="doc-square">
          <div className="doc-square-content">
            <div className="doc-icon">
              <FontAwesomeIcon icon={faFileAlt} />
            </div>
            {item.attributes.name}
          </div>
        </div>
      </a>
    );
  } else {
    return (
      <a href={item.attributes.url} target="_blank" rel="noopener noreferrer">
        <div className="doc-square">
          <div className="doc-square-content">
            <div className="doc-icon">
              {favSrc ? (
                <FaviconWithFallbacks
                  domain={favSrc}
                  store={docStore}
                  doCache={true}
                />
              ) : (
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              )}
            </div>
            {item.attributes.name || item.attributes.url}
          </div>
        </div>
      </a>
    );
  }
};

export default observer(DocumentSquare);

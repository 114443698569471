import { useState } from "react";
import { observer } from "mobx-react-lite";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import {
  faFileAlt,
  faEllipsisH,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "../../stores/utils";
import { useQuery } from "react-query";
import { useEffect } from "react";
import "./DocumentsPopup.scss";
import { isValidUrl } from "../../utilities/dom";
import Honeybadger from "honeybadger-js";
import FaviconWithFallbacks from "../FaviconWithFallbacks/FaviconWithFallbacks";

const DocumentsPopup = () => {
  const {
    api,
    appStore,
    callStore,
    docStore,
    userStore,
    viewStore,
  } = useStores();
  const [docs, setDocs] = useState([]);
  const [otherRoomsDocs, setOtherRoomsDocs] = useState([]);
  const guestToken =
    !userStore.userId && localStorage.getItem("participant-token");

  const fetchDocList = () => {
    return api.getDocuments(callStore.currentRoomId);
  };

  const fetchOtherRooms = () => {
    return api.getOtherRoomDocuments(callStore.currentRoomId);
  };

  const fetchLinks = () => {
    return docStore.getLinks(callStore.currentRoomId, guestToken);
  };

  const fetchOtherRoomLinks = () => {
    return docStore.getOtherRoomLinks(callStore.currentRoomId, guestToken);
  };

  const query = useQuery(
    ["groups/groupDocs", callStore.currentRoomId],
    fetchDocList
  );

  const otherRoomsQuery = useQuery(
    ["groups/otherGroupDocs", callStore.currentRoomId],
    fetchOtherRooms
  );

  const linkQuery = useQuery(
    ["groups/groupLinks", callStore.currentRoomId],
    fetchLinks
  );

  const otherLinksQuery = useQuery(
    ["groups/otherGroupLinks", callStore.currentRoomId],
    fetchOtherRoomLinks
  );
  docStore.setPopupQuery(linkQuery);
  docStore.setPopupOtherQuery(otherLinksQuery);

  const mapDocs = (docs) => {
    return docs.map((x) => ({
      id: x.id,
      name: x.attributes.name,
      timestamp: x.attributes["updated-at"],
    }));
  };

  const mapLinks = (links) => {
    return links.map((x) => ({
      id: x.id,
      name: x.attributes.name || x.attributes.url,
      url: x.attributes.url,
      favSrc: isValidUrl(x.attributes.url)?.origin,
      isUrl: true,
      timestamp: x.attributes["updated-at"],
    }));
  };

  const sortCombinedData = (data) => {
    return data.sort((a, b) =>
      a.timestamp < b.timestamp ? 1 : a.timestamp > b.timestamp ? -1 : 0
    );
  };

  useEffect(() => {
    let combinedData = [];

    if (query.data) {
      const docs = query.data?.data?.data;
      if (docs.length > 0) {
        const mappedDocs = mapDocs(docs);
        combinedData = [...mappedDocs];
      }
    }

    if (linkQuery.data) {
      const links = linkQuery.data?.data?.data;
      if (links.length > 0) {
        const mappedLinks = mapLinks(links);
        combinedData = [...combinedData, ...mappedLinks];
      }
    }

    if (combinedData.length > 0) {
      const sortedData = sortCombinedData(combinedData);
      setDocs(sortedData);
    }
  }, [query.data, setDocs, linkQuery.data]);

  useEffect(() => {
    let combinedData = [];

    if (otherRoomsQuery.data) {
      const docs = otherRoomsQuery.data?.data?.data;
      if (docs.length > 0) {
        const mappedDocs = mapDocs(docs);
        combinedData = [...mappedDocs];
      }
    }

    if (otherLinksQuery.data) {
      const links = otherLinksQuery.data?.data?.data;
      if (links.length > 0) {
        const mappedLinks = mapLinks(links);
        combinedData = [...combinedData, ...mappedLinks];
      }
    }

    if (combinedData.length > 0) {
      const sortedData = sortCombinedData(combinedData);
      setOtherRoomsDocs(sortedData);
    }
  }, [otherRoomsQuery.data, setOtherRoomsDocs, otherLinksQuery.data]);

  const selectDoc = async (id) => {
    await docStore.shareDocWithGroup(id, callStore.currentRoomId);
  };

  const createNote = async () => {
    const groupId = callStore.currentRoomId;
    const sessionId = callStore.sessionId;
    try {
      const response = await api.createDocument(
        {
          name: `${userStore.firstishName}'s Notes`,
        },
        groupId,
        sessionId
      );
      await selectDoc(response.data.data.id);
      viewStore.hideDocumentsList();
    } catch (e) {
      Honeybadger.notify(e);
    }
    query.refetch();
  };

  const showDocumentSettings = (e, docId) => {
    e.stopPropagation();
    viewStore.hideDocumentsList();
    docStore.showDocumentSettingsDocId(docId);
  };

  const groupName = callStore.currentRoom?.attributes?.name;
  const spaceName = `#${callStore?.sessionAttributes?.name}`;

  const showHeaders = otherRoomsDocs.length > 0;

  const showLinkModal = (e, linkId) => {
    e.stopPropagation();
    docStore.showLinkModal(linkId);
  };

  const handleLinkUpdate = (e, linkId) => {
    e.preventDefault();
    e.stopPropagation();
    docStore.setLinkUpdate(true);
    showLinkModal(e, linkId);
  };

  return (
    <div className="documents-popup">
      <div className="documents-scroller">
        <div className="documents-list">
          <div className="menu">
            <ul className="menu-list">
              <p className="menu-label">"{groupName}" docs</p>
              {docs.map((doc) =>
                doc.isUrl ? (
                  <li key={doc.id}>
                    <Tippy
                      content={doc.name}
                      appendTo="parent"
                      placement="right"
                      theme="light"
                      delay={[750, 0]}
                    >
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="doc-row-link"
                      >
                        <div className="doc-name">
                          {doc.favSrc ? (
                            <FaviconWithFallbacks
                              domain={doc.favSrc}
                              store={docStore}
                              doCache={true}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          )}
                          <span>{doc.name}</span>
                        </div>
                        <div
                          className="doc-row-options"
                          onClick={(event) => handleLinkUpdate(event, doc.id)}
                        >
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </div>
                      </a>
                    </Tippy>
                  </li>
                ) : (
                  appStore.officeMode && (
                    <li key={doc.id}>
                      <Tippy
                        content={doc.name}
                        appendTo="parent"
                        placement="right"
                        theme="light"
                        delay={[750, 0]}
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          onClick={() => selectDoc(doc.id)}
                          className="doc-row-link"
                        >
                          <div className="doc-name">
                            <FontAwesomeIcon icon={faFileAlt} />
                            <span>{doc.name}</span>
                          </div>
                          <div
                            className="doc-row-options"
                            onClick={(event) =>
                              showDocumentSettings(event, doc.id)
                            }
                          >
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </div>
                        </a>
                      </Tippy>
                    </li>
                  )
                )
              )}
              {showHeaders && (
                <p className="menu-label">
                  {appStore.officeMode ? spaceName : "Other"} docs
                </p>
              )}
              {otherRoomsDocs.map((doc) =>
                doc.isUrl ? (
                  <li key={doc.id}>
                    <Tippy
                      content={doc.name}
                      appendTo="parent"
                      placement="right"
                      theme="light"
                      delay={[750, 0]}
                    >
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="doc-row-link"
                      >
                        <div className="doc-name">
                          {doc.favSrc ? (
                            <FaviconWithFallbacks
                              domain={doc.favSrc}
                              store={docStore}
                              doCache={true}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          )}
                          <span>{doc.name}</span>
                        </div>
                        <div
                          className="doc-row-options"
                          onClick={(event) => handleLinkUpdate(event, doc.id)}
                        >
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </div>
                      </a>
                    </Tippy>
                  </li>
                ) : (
                  appStore.officeMode && (
                    <li key={doc.id}>
                      <Tippy
                        content={doc.name}
                        appendTo="parent"
                        placement="right"
                        theme="light"
                        delay={[750, 0]}
                      >
                        {/* eslint-disable-next-line */}
                        <a
                          onClick={() => selectDoc(doc.id)}
                          className="doc-row-link"
                        >
                          <div className="doc-name">
                            <FontAwesomeIcon icon={faFileAlt} />
                            <span>{doc.name}</span>
                          </div>
                          <div
                            className="doc-row-options"
                            onClick={(event) =>
                              showDocumentSettings(event, doc.id)
                            }
                          >
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </div>
                        </a>
                      </Tippy>
                    </li>
                  )
                )
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="buttons-container">
        {appStore.officeMode && (
          <Tippy content="Add a new note" appendTo="parent">
            <div>
              <div
                onClick={createNote}
                className="button is-sblue is-rounded is-outlined is-small new-document-button"
              >
                <FontAwesomeIcon icon={faPlus} />{" "}
                <span className="new-document-button-label">Note</span>
              </div>
            </div>
          </Tippy>
        )}
        <Tippy content="Add a new link" appendTo="parent">
          <div>
            <div
              onClick={(e) => showLinkModal(e, true)}
              className="button is-spurple is-rounded is-outlined is-small new-document-button"
            >
              <FontAwesomeIcon icon={faPlus} />{" "}
              <span className="new-document-button-label">Link</span>
            </div>
          </div>
        </Tippy>
      </div>
    </div>
  );
};

export default observer(DocumentsPopup);
